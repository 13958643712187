(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// SLIDER
var c1 = 'vs-trust-content-container',
  _C1 = document.querySelector('.'.concat(c1)),
  isC1 = document.getElementsByClassName(c1),
  r1 = 'vs-trust-arrow-right',
  l1 = 'vs-trust-arrow-left';
var _R1, _L1, N1;
if (isC1.length > 0) {
  _R1 = document.querySelector('.'.concat(r1)), _L1 = document.querySelector('.'.concat(l1)), N1 = _C1.children.length;
}
var c2 = 'vs-reviews-content-container',
  _C2 = document.querySelector('.'.concat(c2)),
  isC2 = document.getElementsByClassName(c2),
  r2 = 'vs-reviews-arrow-right',
  l2 = 'vs-reviews-arrow-left';
;
var _R2, _L2, N2;
if (isC2.length > 0) {
  _R2 = document.querySelector('.'.concat(r2));
  _L2 = document.querySelector('.'.concat(l2));
  N2 = _C2.children.length;
}
var NF = 30,
  TFN = {
    'bounce-out': function bounceOut(k) {
      var a = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2.75;
      var b = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1.5;
      return 1 - Math.pow(1 - k, a) * Math.abs(Math.cos(Math.pow(k, b) * (n + .5) * Math.PI));
    }
  };
var i = 0,
  e,
  x0 = null,
  locked = false,
  w,
  ini,
  fin,
  rID = null,
  anf,
  n,
  calc_i,
  el,
  N;
function stopAni() {
  cancelAnimationFrame(rID);
  rID = null;
}
;
function ani1(cf) {
  calc_i = ini + (fin - ini) * TFN['bounce-out'](cf / anf);
  if (isNaN(calc_i)) {
    calc_i = i;
  }

  //checkiGoAround( calc_i )
  _C1.style.setProperty('--i', checkiStop(calc_i));
  if (cf === anf) {
    stopAni();
    return;
  }
  rID = requestAnimationFrame(ani1.bind(this, ++cf));
}
;
function ani2(cf) {
  calc_i = ini + (fin - ini) * TFN['bounce-out'](cf / anf);
  if (isNaN(calc_i)) {
    calc_i = i;
  }
  //checkiGoAround( calc_i )
  _C2.style.setProperty('--i', checkiStop(calc_i));
  if (cf === anf) {
    stopAni();
    return;
  }
  rID = requestAnimationFrame(ani2.bind(this, ++cf));
}
;
function unify(e) {
  return e.changedTouches ? e.changedTouches[0] : e;
}
;
function lock(e) {
  x0 = unify(e).clientX;
  locked = true;
}
;
function drag(e) {
  //če je to vključeno, ne moreš potisnit strani gor in dol, če primeš na ta območje sliderja. Zdaj je to izključeno ampak je problem, da potem ko premikaš slider, se tudi stran premika levo in desno. mogoče za naprej je tukaj ena komplicirana rešitev https://stackoverflow.com/questions/27345365/how-to-prevent-vertical-scroll-on-swipe-left-right
  // e.preventDefault();

  if (locked) {
    var dx = unify(e).clientX - x0,
      f = +(dx / w).toFixed(2);
    if (this.classList.contains(c1)) {
      _C1.style.setProperty('--i', checkiStop(i - f, N = N1));
    } else if (this.classList.contains(c2)) {
      _C2.style.setProperty('--i', checkiStop(i - f, N = N2));
    }
  }
}
;
function move(e) {
  i = readi(this);
  N = defineN(this);
  if (locked) {
    var dx = unify(e).clientX - x0,
      s = Math.sign(dx),
      f = +(s * dx / w).toFixed(2);
    ini = i - s * f;
    if ((i > 0 || s < 0) && (i < N - 1 || s > 0) && f > .2) {
      i -= s;
      f = 1 - f;
    }
    fin = i;
    anf = Math.round(f * NF);
    n = 2 + Math.round(f);
    if (this.classList.contains(r1) || this.classList.contains(l1) || this.classList.contains(c1)) {
      ani1(0);
    } else if (this.classList.contains(r2) || this.classList.contains(l2) || this.classList.contains(c2)) {
      ani2(0);
    }
    x0 = null;
    locked = false;
  }
}
;
function readi(el) {
  //read --i depending on what user wants to move (top trust, reviews...)
  if (el.classList.contains(r1) || el.classList.contains(l1) || el.classList.contains(c1)) {
    var element = window.getComputedStyle(_C1, null);
    i = Math.round(Number(element.getPropertyValue("--i")));
    return i;
  } else if (el.classList.contains(r2) || el.classList.contains(l2) || el.classList.contains(c2)) {
    var _element = window.getComputedStyle(_C2, null);
    i = Math.round(Number(_element.getPropertyValue("--i")));
    return i;
  }
}
function defineN(el) {
  if (el.classList.contains(r1) || el.classList.contains(l1) || el.classList.contains(c1)) {
    N = _C1.children.length;
    return N;
  } else if (el.classList.contains(r2) || el.classList.contains(l2) || el.classList.contains(c2)) {
    N = _C2.children.length;
    return N;
  }
}
function checkiGoAround(i, N) {
  if (i > N - 0.5) {
    i = 0;
  }
  if (i < -0.5) {
    i = N - 1;
  }
  return i;
}
function checkiStop(i, N) {
  if (i > N - 0.5) {
    i = N - 1;
  }
  if (i < -0.5) {
    i = 0;
  }
  return i;
}
function click() {
  //set e depending on whether user clicked left or right
  var f;
  if (this.classList.contains('vs-arrow-right') == true) {
    f = 1;
  } else if (this.classList.contains('vs-arrow-left') == true) {
    f = -1;
  }
  if (this.classList.contains(r1) || this.classList.contains(l1)) {
    i = readi(_C1);
    i = i + f;
    i = checkiGoAround(i, N = N1);
    _C1.style.setProperty('--i', i);
  } else if (this.classList.contains(r2) || this.classList.contains(l2)) {
    i = readi(_C2);
    i = i + f;
    i = checkiGoAround(i, N = N2);
    _C2.style.setProperty('--i', i);
  }
}
;
function size() {
  w = window.innerWidth;
}
;
size();
if (isC1.length > 0) {
  _C1.style.setProperty('--n', N1);
}
if (isC2.length > 0) {
  _C2.style.setProperty('--n', N2);
}
addEventListener('resize', size, false);
if (isC1.length > 0) {
  _C1.addEventListener('mousedown', lock, {
    passive: true
  });
  _C1.addEventListener('touchstart', lock, {
    passive: true
  });
  _C1.addEventListener('mousemove', drag, {
    passive: true
  });
  _C1.addEventListener('touchmove', drag, {
    passive: true
  });
  _C1.addEventListener('mouseup', move, {
    passive: true
  });
  _C1.addEventListener('touchend', move, {
    passive: true
  });
  _R1.addEventListener('click', click, {
    passive: true
  });
  _L1.addEventListener('click', click, {
    passive: true
  });
}
if (isC2.length > 0) {
  _C2.addEventListener('mousedown', lock, {
    passive: true
  });
  _C2.addEventListener('touchstart', lock, {
    passive: true
  });
  _C2.addEventListener('mousemove', drag, {
    passive: true
  });
  _C2.addEventListener('touchmove', drag, {
    passive: true
  });
  _C2.addEventListener('mouseup', move, {
    passive: true
  });
  _C2.addEventListener('touchend', move, {
    passive: true
  });
  _R2.addEventListener('click', click, {
    passive: true
  });
  _L2.addEventListener('click', click, {
    passive: true
  });
}

// // FAQ

// const faqQuestions = document.querySelectorAll('.vs-faq-question');

// //Add click event listener to each question
// faqQuestions.forEach(question => {
//   const questionTitle = question.querySelector('h3');
//   const plusMinusIcon = questionTitle.querySelector('.vs-plus-minus-faq');
//   const answer = question.querySelector('.vs-faq-answer');

//   if ( questionTitle != null ) {
//     questionTitle.addEventListener('click', () => {
//       const isAnswerOpen = answer.classList.contains('vs-faq-answer-open');

//       if ( !isAnswerOpen ) {
//         answer.classList.add('vs-faq-answer-open');
//         console.log( isAnswerOpen );
//       }

//       // Collapse all other answers
//       faqQuestions.forEach(otherQuestion => {
//         if (otherQuestion !== question) {
//           const otherAnswer = otherQuestion.querySelector('.vs-faq-answer');
//           otherAnswer.classList.remove('vs-faq-answer-open');
//           otherQuestion.classList.remove('open');

//           // Remove the plus/minus icon class
//           const otherPlusMinusIcon = otherQuestion.querySelector('.vs-plus-minus-faq');
//           otherPlusMinusIcon.classList.remove('vs-show-minus');
//         }
//       });

//       // Toggle the answer's open state

//       answer.classList.add('vs-faq-answer-open');
//       // answer.classList.add('vs-faq-answer-open');

//       // Toggle the plus/minus icon class
//       plusMinusIcon.classList.toggle('vs-show-minus', !isAnswerOpen);

//       // Toggle the open state
//       question.classList.toggle('open', !isAnswerOpen);
//     });
//   }
// });

// FAQ
var faqQuestions = document.querySelectorAll('.vs-faq-question');

// Add click event listener to each question
faqQuestions.forEach(function (question) {
  var questionTitle = question.querySelector('h3');
  var plusMinusIcon = questionTitle.querySelector('.vs-plus-minus-faq');
  var answer = question.querySelector('.vs-faq-answer');
  if (questionTitle != null) {
    questionTitle.addEventListener('click', function () {
      var isAnswerOpen = answer.classList.contains('vs-faq-answer-open');

      // Collapse all other answers
      faqQuestions.forEach(function (otherQuestion) {
        if (otherQuestion !== question) {
          var otherAnswer = otherQuestion.querySelector('.vs-faq-answer');
          otherAnswer.classList.remove('vs-faq-answer-open');
          otherQuestion.classList.remove('open');

          // Remove the plus/minus icon class
          var otherPlusMinusIcon = otherQuestion.querySelector('.vs-plus-minus-faq');
          otherPlusMinusIcon.classList.remove('vs-show-minus');
        }
      });

      // Toggle the answer's open state
      if (isAnswerOpen) {
        answer.classList.remove('vs-faq-answer-open');
        question.classList.remove('open');
      } else {
        answer.classList.add('vs-faq-answer-open');
        question.classList.add('open');
      }

      // Toggle the plus/minus icon class
      plusMinusIcon.classList.toggle('vs-show-minus', !isAnswerOpen);
    });
  }
});

// // generate coupon code for lead gen for Bit Form
// function generateRandomString(length = 6) {
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   let result = '';
//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * characters.length);
//     result += characters[randomIndex];
//   }
//   return result;
// }

// // Add event listener to the email field
// document.getElementsByName('email-b1-4')[0].addEventListener('input', () => {
//   const emailField = document.getElementsByName('email-b1-4')[0];
//   const randomField = document.getElementsByName('text-1-7')[0];

//   if (emailField.value.trim()) {
//     // Populate the random field once the email field is filled
//     randomField.value = generateRandomString();
//   }
// });

//   // Add event listener to button-b1-1 to simulate delayed click
//   document.getElementsByName('button-b1-1')[0].addEventListener('click', () => {
//     setTimeout(() => {
//       const secondButton = document.querySelector('.hustle-button-icon.hustle-button-close.has-background');
//       if (secondButton) {
//         secondButton.click(); // Simulates a click on the second button
//       } else {
//         console.error('Second button not found.');
//       }
//     }, 1500); // 1.5-second delay

//   });

},{}]},{},[1]);
